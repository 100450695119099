<template>
    <div class="relative">
        <InnerHeader />
        <div class="flex flex-col lg:flex-row ais jcb main-container pt-6 lg:border-t border-gray-100 relative px-10 md:px-0 md:mt-8 lg:mt-14">
            <!-- <div class="absolute right-0 top-0 h-10p w-80p"></div> -->
            <h1 class="min-w-max lg:text-xl xl:text-2xl merienda-bold uppercase order-2 md:order-1 mt-2 md:mt-0 text-brown-light">{{$t('Services')}}</h1>
            <ol class="min-w-max flex aic space-x-3 text-xs order-1 md:order-2">
                <li><router-link to="/" class="nunitoSans-regular text-gray-500">{{$t('Home')}}</router-link></li>
                <li class="text-gray-400"><i class="fa fa-angle-right"></i></li>
                <li class="nunitoSans-bold">{{$t('Services')}}</li>
            </ol>
        </div>
        <div class="main-container lg:mt-12 flex flex-wrap ais px-8 md:px-0">
            <div class="flex flex-col w-full relative pb-10 xl:pb-20">
                <div class="flex flex-col xl:flex-row jcb w-full relative overflow:visible px-2 xl:px-0">
                    <div class="flex flex-col jcb w-full xl:w-6/12 service-main-img pb-12 lg:pb-24 xl:pb-44 mt-12">
                        <img src="img/service.webp" alt="#" class="w-full h-full object-cover shadow-2xl">
                    </div>
                    <div class="relative flex flex-col jcs w-full xl:w-5/12">
                        <div class="flex flex-col">
                            <h2 class="text-xl lg:text-2xl text-gray-900 comfortaa-regular italic ">
                                {{$t('Immer')}}
                                <br>
                                <b class="text-2xl lg:text-3xl merienda-bold text-brown-light">{{$t('Frisch')}}</b>
                            </h2>
                            <!-- <div class="ub-2 mt-10 mid-shadow">
                            </div> -->
                        </div>
                        <p class="mt-10 text-gray-600 text-sm lg:text-base leading-5 mx-auto Exo2-Light mb-auto">
                            Zu jedem guten Frühstück gehören frische Brötchen vom Bäcker. In unserem Shop können Sie Ihre Brötchen online bestellen - ganz einfach und sicher. 
                            OVEN FRESH nehmen wir wörtlich. Nur Brötchen, die Sie aus dem Ofen nehmen, sind wirklich ofenfrisch. Deshalb liefern wir Ihnen unsere Brötchen täglich gebacken, 
                            knusprig und frisch. Kaufen Sie jetzt Ihre Lieblingsbäckerbrötchen und schmecken Sie den Unterschied!
                            <br>
                            <br>
                            Wir backen mit Leib und Seele – immer mit dem Ziel, Ihnen ein leckeres „Hmmmm“ auf die Lippen zu zaubern.
                            Wir garantieren, dass Ihre bestellten Backwaren mit den besten Zutaten, Hingabe und Sorgfalt frisch gebacken und verpackt wurden.
                        </p>

                        <img src="img/service-3.webp" alt="#" class="w-full xl:w-10/12 object-cover mt-20 xl:mt-32 ml-auto shadow-2xl">
                        <svg width="404" height="304" fill="none" viewBox="0 0 404 304" class="absolute -bottom-16 right-36 -z-1">
                            <defs>
                                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="304" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                        </svg>
                    </div>
                </div>
                <div class="flex flex-col lg:flex-row aic jcc lg:space-x-20 px-2 xl:px-0 mt-10 lg:mt-24 xl:mt-32">
                    <div class="flex items-center lg:items-end justify-center lg:justify-end serv-sub-left relative w-full lg:w-1/2 order-2 lg:order-1">
                        <img src="img/service-1.webp" alt="#" class="w-full h-320p object-cover shadow-2xl">
                    </div>
                    <div class="flex flex-col ais w-full lg:w-1/2 mt-12 lg:mt-0 order-1 lg:order-2 mb-10 lg:mb-0">
                        <h2 class="text-xl lg:text-2xl text-gray-900 comfortaa-regular italic ">
                            {{$t('Ein Jahresabonnement')}}
                            <br>
                            <b class="text-2xl lg:text-3xl merienda-bold text-brown-light">{{$t('für täglich frische Brötchen')}}</b>
                        </h2>
                        <p class="mt-10 text-gray-600 text-sm lg:text-base leading-5 mx-auto Exo2-Light mb-auto">
                            Morgens bequem und komfortabel das Frühstück nach Hause geliefert
                            bekommen? Unser brötchenshop-Semmel-Abo-Lieferservice ist die ideale.
                            Unser Semmel-Abo schenkt mehr Zeit & Genuss, denn wir liefern frische Semmeln
                            am Morgen direkt an die Haustüre.
                            Damit Sie morgens länger schlafen und auf das Anstehen beim Bäcker verzichten
                            können. Genießen Sie Frische, Qualität und den vollen Komfort unseres
                            Frühstücksservices von brötchenshop.
                            Ofenfrische Backwaren werden bequem im Abo bis an die Haustür geliefert. Mit
                            den Backwaren von den Brötchenshop Frühstücksdiensten ein Abonnement mal
                            sinnvoll nutzen                        
                        </p>
                    </div>
                </div>
                <div class="flex flex-col lg:flex-row aic jcc lg:space-x-20 px-2 xl:px-0 mt-10 lg:mt-24 xl:mt-32">
                    <div class="flex flex-col ais w-full xl:w-7/12 mt-12 lg:mt-0 mb-10 lg:mb-0">
                        <h2 class="text-xl lg:text-2xl text-gray-900 comfortaa-regular italic ">
                            {{$t('Backen und liefern,')}}
                            <br>
                            <b class="text-2xl lg:text-3xl merienda-bold text-brown-light">{{$t('wenn Sie schlafen')}}</b>
                        </h2>
                        <p class="mt-10 text-gray-600 text-sm lg:text-base leading-5 mx-auto Exo2-Light mb-auto">
                            Unser Leben ist leider sehr Stressig geworden, selbst am Wochenende bleibt selten Zeit für sich und seinen lieben. 
                            Es wird Zeit daran etwas zu ändern, können Sie sich etwas mehr Zeit, die Sie für sich und ihre Familie nutzen können. Wie soll das gehen?
                            <br>
                            <br>
                            Nutzen Sie zum Beispiel unseren Brötchenlieferservice, damit ersparen Sie sich den Weg zum Bäcker. Unser Brötchenbringdienst versorgt Sie Morgen für Morgen 
                            mit frischen Brötchen und Backwaren direkt vom Handwerks Bäcker. Sie bestellen ganz bequem online zusammen mit Ihrer Familie, am nächsten Morgen 
                            hängen die Ofen frischen Brötchen direkt an Ihrer Haustüre. Sie müssen also nicht einmal das Haus verlassen, um Ihre geliebten Frühstücksbrötchen zu genießen.
                        </p>
                    </div>
                    <div class="flex items-center lg:items-end justify-center lg:justify-end serv-sub-left relative w-full xl:w-4/12">
                        <img src="img/service-2.webp" alt="#" class="w-full object-cover shadow-2xl">
                    </div>
                </div>
            </div>
        </div>
        <MainFooter />
    </div>
</template>
<script>
import InnerHeader from "../components/InnerHeader.vue"
import MainFooter from "../components/MainFooter.vue"
export default {
    components: { InnerHeader, MainFooter }
};
</script>
